export default {
  "admin": {
    "allTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All-time"])},
    "ap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AP"])},
    "config": {
      "canChangeWifiSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can change WIFI settings?"])},
      "confirm": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this config?"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete config"])}
      },
      "omada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a omada file."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Config"])},
      "titleModal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add configuration"])},
      "unifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a unifi file"])}
    },
    "controllers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controllers"])},
    "customer": {
      "addCustomerTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customers"])},
      "addCustomersTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a customer"])},
      "addLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create location"])},
      "addLocationsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locations"])},
      "editDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit details"])},
      "inviteUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailaddress"])},
      "linkStripe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link to Stripe"])},
      "locationUnknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location unknown"])},
      "manageLinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage links"])},
      "manageUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage user"])},
      "newLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The created location will have the same address as entered for the customer"])},
      "noCustomers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No customers found"])},
      "noInvites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No invites"])},
      "noLocations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No locations found"])},
      "noUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No user"])},
      "selectCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a customer"])},
      "selectCustomers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a customer"])},
      "selectExistingCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose an existing company"])},
      "selectLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select location"])},
      "selectPlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a plan"])},
      "selectProvider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select provider"])},
      "stripeLinked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stripe linked"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customers"])},
      "selectType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select location type"])},
      "confirmDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this?"])},
      "freeTier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free tier"])},
      "plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan"])},
      "selectCaas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select if this location is CaaS"])},
      "caas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connectivity as a Service (CaaS)"])},
      "demo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable demo mode (for example PoC)"])},
      "accountAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use invoice email address for the account"])},
      "createAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create an account for amplr.cloud"])},
      "connectionType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a connectiontype"])}
    },
    "dashboard": {
      "allGood": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Everything OK"])},
      "controllers": {
        "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controllers"])}
      },
      "locations": {
        "newLocationsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ready for configuration"])}
      },
      "portal": {
        "count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portal"])}
      }
    },
    "devices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devices"])},
    "gateway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gateway"])},
    "installLocations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ready for installation"])},
    "location": {
      "chooseConfig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose configuration type"])},
      "chooseControllers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose netwerkcontrollers"])},
      "chooseHardwareVendors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the different device manufacturers"])},
      "chooseOmadaController": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the Omada controller"])},
      "choosePortal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose existing portal"])},
      "chooseUnifiController": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the Unifi controller"])},
      "config": {
        "startConfig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start configuring controllers"])},
        "step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration type"])},
        "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Router"])},
        "step3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch"])},
        "step4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access Point"])},
        "wifiConfigDisabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This config doesn't allow you to change the wifi names or passwords. Please continue without."])},
        "step5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wireless Networks"])},
        "step6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report"])}
      },
      "confirm": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to archive this location? Pay Attention: The live data will also be archived"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archive/pause location"])}
      },
      "copyPortal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy existing portal settings"])},
      "createNewLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new location for this company"])},
      "createPortal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create portal"])},
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location details"])},
      "existingPortal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Existing portal"])},
      "finish": {
        "ap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access Points"])},
        "chooseEnclosure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Which enclosure was used?"])},
        "discoveredDevices": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["These devices are active at this location and will be included in the work order."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Used Devices"])}
        },
        "gateway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Router"])},
        "noEnclosure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No patch box"])},
        "switch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switches"])},
        "chooseAccessoires": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What materials were used for finishing"])},
        "chooseAccessoire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose product"])}
      },
      "finishConfig": {
        "step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validate installation"])},
        "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validate billing"])},
        "addInstaller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add installer"])}
      },
      "label": {
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locatie"])},
        "apVendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AP"])},
        "gatewayVendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gateway"])},
        "switchVendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch(es)"])}
      },
      "peplinkSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the settings for Peplink"])},
      "portal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make captive portal"])},
      "selectApVendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select customer"])},
      "selectCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a customer"])},
      "selectConfig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select configuration type"])},
      "selectCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the customer"])},
      "selectGatewayVendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select router manufacturer"])},
      "selectOmadaController": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Omada Controller"])},
      "selectPeplinkController": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Peplink location"])},
      "selectPeplinkOrg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Peplink Org"])},
      "selectPeplinkTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Peplink template file"])},
      "selectSwitchVendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select switch manufacturer"])},
      "selectUnifiController": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Unifi controller"])},
      "unknownCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown"])},
      "updateParameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update parameters"])},
      "noInstallionLogs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no installation log yet for this location."])},
      "addInstallationLog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add installation log"])},
      "configReport": {
        "config": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration type"])},
        "sim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sim present"])},
        "internetType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internet type"])},
        "fritz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fritz!Box present"])},
        "vlans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VLANs"])},
        "option138": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Option138"])},
        "option43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Option43"])},
        "wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wifi check"])},
        "portal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guest portal"])},
        "gatewayVendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the gateway vendor"])},
        "apVendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the AP vendor"])},
        "switchVendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the switch vendor"])},
        "devicesAdopted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devices adopted"])},
        "sitesLinked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sites made and linked"])},
        "pppoe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PPPOE"])}
      },
      "addConfigurationLog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add configuration report"])},
      "noConfigurationLogs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no configuration report yet for this location"])},
      "noComments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no comments yet"])},
      "chooseGateway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the manufacturer and controller for the router"])},
      "selectVendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select manufacturer"])},
      "chooseSwitch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the manufacturer and controller for the switch"])},
      "chooseAp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the manufacturer and controller for the access point"])},
      "diyInstallation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The customer will do the installation himself"])},
      "exportConfigurationLog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export configuration report"])},
      "exportInstallationLog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export installation log"])},
      "exportLog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export report"])},
      "noWlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No vendor is given for access points. Wireless networks can't be loaded. Please continue."])},
      "changeLocationStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is a diy install"])},
      "changeLocationName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The name of the location is different than the customer name"])},
      "changeName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use a location name instead of the company name. Only use this when the location name is different from the company name."])},
      "restoreConfirm": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heractiveer locatie"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restore a location. The location will be visible again for the customer."])}
      },
      "subscription": {
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add the subscription id from stripe to view the details of the location subscription."])}
      },
      "vendors": {
        "modal": {
          "peplink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link Peplink"])},
          "omada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link TP-Link Omada"])},
          "unifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link Unifi"])},
          "zerotier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link Zerotier"])}
        },
        "peplink": {
          "currentId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current linked ID"])},
          "newId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New selected ID"])}
        }
      },
      "selectPeplinkInstance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Peplink instance"])},
      "choosePeplinkController": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peplink instance"])},
      "choosePeplinkOrg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peplink orginasation"])},
      "selectPeplinkGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Peplink group"])},
      "choosePeplinkGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peplink group"])},
      "chooseOmada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omada"])},
      "selectOmadaSite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Omada site"])},
      "chooseOmadaSite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omada site"])},
      "chooseVendorIds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Network vendors"])},
      "ppsk": {
        "modal": {
          "omada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link TP-Link Omada PPSK"])}
        },
        "currentId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PPSK Profile ID"])},
        "currentSSID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSID linked to PPSK"])},
        "defaultVid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed VLAN for tenants"])}
      },
      "choosePpskProfileAndSSID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PPSK Profile"])},
      "selectPpskProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the PPSK profile"])},
      "ppskProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PPSK Profile"])},
      "selectWlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select SSID"])},
      "ppskSsid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSID"])},
      "chooseZerotierNode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a Zerotier node"])},
      "selectZerotierNode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Zerotier node"])},
      "caasHardware": {
        "modal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CaaS hardware"])},
        "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export to CSV"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allocated products for this location"])}
      },
      "fixedVlanId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed VLAN for all tenants"])},
      "fixedVlanIdPlaceHolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["20"])}
    },
    "locations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locations"])},
    "newLocations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ready for configuration"])},
    "portal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portal today"])},
    "switches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switches"])},
    "table": {
      "config": {
        "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
        "controllerUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
        "lastUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last updated"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
        "vendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendor"])},
        "controllerVersion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controller version"])},
        "authorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authorized"])},
        "nodeId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zerotier ID"])},
        "nodeIp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Node IP"])},
        "online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online"])}
      },
      "customer": {
        "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
        "alias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alias"])},
        "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search on customer name"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
        "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT"])},
        "noResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>No customers found.</p><p>Try your luck and adapt the filters.</p>"])}
      },
      "location": {
        "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
        "ap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access Point(s)"])},
        "createdAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created at"])},
        "customerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
        "noResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>No locations found.</p><p>Try your luck and adapt the filters.</p>"])},
        "portal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guest portal"])},
        "router": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Router"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search on customer or location"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "switch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch(es)"])},
        "userName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
        "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
        "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
        "model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Model"])},
        "configReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create config report"])},
        "startConfig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start configuration"])},
        "venue_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venue name"])},
        "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
        "startInstallation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create install report"])},
        "qrImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR image"])},
        "qrLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR label"])},
        "printLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Print label"])},
        "viewConfigReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View config report"])},
        "viewInstallReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View install report"])},
        "locationDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show location"])},
        "viewBilling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View billing"])},
        "snooze": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snooze"])},
        "paymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment method"])},
        "getCaasHardware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List CaaS hardware"])}
      },
      "partners": {
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search on partner name"])}
      },
      "webhook": {
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
        "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])}
      },
      "sales": {
        "hunterName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hunter"])},
        "venueType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venue type"])},
        "venueName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venue name"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
        "postalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zip code"])},
        "wantsOffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wants quote"])},
        "follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Needs follow up"])},
        "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created at"])}
      }
    },
    "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
    "wlan": {
      "addRandomGeneratedPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate random password"])},
      "private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Private network"])},
      "public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public network"])}
    },
    "partners": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partners"])},
      "addModal": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a partner"])}
      },
      "addAccessModal": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add access"])}
      }
    },
    "partner": {
      "confirm": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete partner"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this partner?"])}
      }
    },
    "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archived"])},
    "sales": {
      "totalVisits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All hunters today"])},
      "yourVisits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your visits"])},
      "allTimeVisits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All time visits"])},
      "calc": {
        "oneOff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total one-off"])},
        "mrr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly Recurring Revenue"])},
        "amplrSetup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMPLR.setup"])},
        "hardware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hardware"])},
        "hardwareCaas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hardware CaaS MRR"])},
        "servicesMrr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services CaaS MRR"])}
      },
      "configCalc": {
        "devices": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devices"])},
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add the products for the quotation"])}
        },
        "connections": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internet connections"])},
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the desired internet connections"])}
        },
        "plan": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service plan"])},
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pick the desired SLA plan for the calculation"])}
        },
        "installCat": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Install category"])},
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the install category based on the estimations for installation"])}
        },
        "amountVenues": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount of venues"])},
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the amount of venues"])}
        },
        "addDevice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add devices by searching and selecting."])},
        "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculation details"])},
        "calculation": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculation"])},
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detailed overview of the calculation"])}
        }
      },
      "update": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update successfully saved"])}
      },
      "totalMrr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total MRR"])},
      "totalOneOff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total one off cost"])}
    },
    "page": {
      "sales": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales hunters"])}
      },
      "inventory": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inventory"])}
      },
      "products": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMPLR Products"])}
      },
      "allocated": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allocated CaaS"])}
      }
    },
    "statusModal": {
      "controllers": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cloudcontrollers"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opens a new tab with the controller interface for this location"])}
      }
    },
    "snoozed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snoozed"])},
    "customers": {
      "table": {
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])}
      }
    },
    "payments": {
      "create": {
        "plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA (Service plan)"])},
        "placeholder": {
          "plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose SLA"])},
          "connectionType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose internet connection"])},
          "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["999.99"])}
        },
        "connectionType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internet connection"])},
        "hasFailover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Has failover"])},
        "hasPeplink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Has Peplink router"])},
        "internetPending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internet not installed yet"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Stripe price"])},
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Give the amount for the subscription"])},
        "hardwarePrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Give the hardwareprice"])},
        "caasDevices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detected devices"])},
        "removedCaasDevices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non AMPLR devices"])}
      }
    },
    "inventory": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "ean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EAN"])},
      "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
      "purchasePrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase price"])},
      "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total value"])},
      "stripeLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stripe"])},
      "productNotFound": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Product with ean: ", _interpolate(_named("ean")), " not found"])},
      "editProduct": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add products to inventory"])}
      },
      "addInventory": {
        "productsToAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product that will be added to inventory"])},
        "addButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to inventory"])}
      },
      "addTnventory": {
        "noProducts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No products to add yet."])}
      },
      "addProduct": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new product reference"])}
      },
      "form": {
        "label": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendor product name"])},
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product type"])},
          "model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Model (ex SG2008P)"])},
          "ean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EAN/SKU"])},
          "purchasePrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase price"])},
          "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selling price"])},
          "caasPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CaaS price"])},
          "configPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Config price if applicable"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SG2008P"])},
          "amplrName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMPLR product name"])},
          "supplier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a supplier"])},
          "vendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendor"])},
          "licencePrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra monthly licence price"])}
        },
        "placeholder": {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a product type"])},
          "vendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendor"])},
          "supplier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supplier"])}
        },
        "name": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peplink B ONE 5G"])}
        }
      },
      "deductInventory": {
        "productsToDeduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products that will be deducted"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deduct products"])}
      },
      "deductProducts": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deduct products from inventory"])}
      },
      "amplrProductSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select AMPLR related product"])},
      "amplrProductPlaceHolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose product..."])},
      "createProduct": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new inventory product"])}
      },
      "updateProduct": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update inventory product"])}
      },
      "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new inventory product"])},
      "product": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create AMPLR product"])}
      },
      "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
      "productModel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product model / Description"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
      "noModel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No models"])},
      "models": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Models"])},
      "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update product"])}
    },
    "products": {
      "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose product"])}
    },
    "zerotier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zerotier nodes"])},
    "zerotierNodes": {
      "authorize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authorize"])},
      "authorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authorized"])}
    },
    "allocated": {
      "returnModal": {
        "listHardware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allocated products"])},
        "lostHardware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lost hardware (needs invoicing)"])},
        "archiveLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archive location"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CaaS return processed."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return CaaS hardware"])}
      },
      "buttons": {
        "lostBroken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lost/Broken"])}
      },
      "noResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No allocated products found, select or switch location."])},
      "nothingToReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No products are marked lost or broken."])}
    },
    "amplrProduct": {
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create amplr product"])}
    },
    "suppliers": {
      "selectSupplier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a supplier"])}
    },
    "portalYesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portal yesterday"])}
  },
  "auth": {
    "login": {
      "alreadymember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already have an account?"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
      "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot password?"])},
      "forgotPasswordLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset!"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome back!"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your credentials to access your account"])},
      "logginIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logging in..."])},
      "titleMorning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good morning!"])},
      "titleAfternoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good afternoon!"])},
      "titleEvening": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good evening!"])},
      "body2Fa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in your two factor authentication code to accces amplr.cloud"])},
      "title2Fa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify your account"])},
      "body2FaSms": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Fill in your two factor authentication code to accces amplr.cloud, the code has been sent to <strong>", _interpolate(_named("number")), "</strong>. Didn't receive the code? Request a new one!"])},
      "body2FaMail": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Fill in your two factor authentication code to accces amplr.cloud, the code has been sent to <strong>", _interpolate(_named("mail")), "</strong>. Didn't receive the code? Request a new one!"])},
      "2faSend": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Code resend successful. Check your ", _interpolate(_named("method")), "!"])},
      "requestCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request new code"])}
    },
    "recover": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot password? recover your password with a resetlink."])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send a link"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recover your password"])}
    },
    "register": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create an account for amplr.cloud."])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
      "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose your language"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration is succesfull"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])}
    },
    "reset": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a new password to log in to amplr.cloud"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save password"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change your password"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage your connectivity"])},
    "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMPLR.cloud is designed for you. We want you to take control about your internet and wifi. Get insights, change settings, create  beautiful guest portals and much more."])}
  },
  "button": {
    "admin": {
      "addCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add customer"])},
      "addConfig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add configuration"])},
      "addCustomersToGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add customer"])},
      "addLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add location"])},
      "addLocationsCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add customer location"])},
      "finishInstallation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete"])},
      "inviteUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite users"])},
      "printPublicLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Print label"])},
      "selectCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add existing customer"])},
      "selectLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add existing location"])},
      "startConfig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start configuration"])},
      "addLocationReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add location report"])},
      "locationReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location report"])},
      "addPartner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add partner"])},
      "addAccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add access"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
      "sales": {
        "addVisit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add visit"])}
      },
      "addController": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add controller"])},
      "inventory": {
        "addQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add products"])},
        "addProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new product"])},
        "deductQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deduct products"])}
      },
      "allocated": {
        "return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return CaaS hardware"])}
      }
    },
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove"])},
    "deselectAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deselect all"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "unlock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlock"])},
    "facebookLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login with Facebook"])},
    "googleLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login with Google"])},
    "linkedinLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login with LinkedIn"])},
    "location": {
      "addDeviceGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new group"])}
    },
    "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set up a payment method"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next step"])},
    "payments": {
      "portal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open payment page"])},
      "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create link"])}
    },
    "prev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous step"])},
    "printLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Print Label"])},
    "printQr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Print QR"])},
    "rebootNetwork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reboot network"])},
    "rebootNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reboot now"])},
    "rebooting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reboot in progress"])},
    "reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reload application"])},
    "removeWebhook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove webhook"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "selectAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select All"])},
    "showWebhookLogs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show log"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View"])},
    "webhookResend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resend webhook"])},
    "webhookSending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sending..."])},
    "webhookTest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send test"])},
    "showErrors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View problems"])},
    "customize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customize"])},
    "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish"])},
    "tenants": {
      "addTenants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add tenant(s)"])}
    },
    "createTenants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save tenants"])},
    "addMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add another"])},
    "deactivateNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deactivate now"])},
    "enableTwoFactor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate 2FA"])},
    "disableTwoFactor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disable 2FA"])},
    "2fa": {
      "validating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validating 2FA code..."])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validate code"])}
    },
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy"])},
    "addComment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add comment"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
    "scheduleDeactivation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schedule deactivation"])},
    "payOverDue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay overdue invoices"])},
    "previewPortal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview guestportal"])},
    "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resend"])},
    "reloadList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reload"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show details"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call us"])},
    "restore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restore"])},
    "showCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show customer"])},
    "openStripe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show stripe account"])},
    "editCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit customer"])},
    "removeGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove selected group"])},
    "createSubscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create subscription"])},
    "createInvoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create invoice"])},
    "showLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show location"])},
    "salesAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add another visit"])},
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export visits"])},
    "startAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start again"])},
    "sales": {
      "configcalculation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Config calculation"])},
      "editVisit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit visit"])},
      "saveNewConfig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save new calculation"])},
      "saveChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save changes"])}
    },
    "saveChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save changes"])},
    "impersonate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impersonate"])},
    "stopImpersonating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop imposter mode"])},
    "checkConnection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test connection"])},
    "addController": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add controller"])},
    "editController": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit controller"])},
    "portalSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
    "goBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go back"])},
    "editClient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit device"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "newRefferal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add another"])},
    "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refresh"])},
    "createNewPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create another"])},
    "unlink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlink"])},
    "editLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit link"])},
    "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
    "addPpsk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link PPSK"])},
    "deletePpsk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete PPSK"])},
    "printZerotierLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Print Zerotier label"])},
    "releaseClient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit fixed clients"])},
    "viewGuestData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View guest data"])},
    "openLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open in Stripe"])},
    "createNewReservation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new DHCP reservation"])},
    "createReservation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new DHCP-reservation"])},
    "exportData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export data"])},
    "excell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download as Excel (.xlsx)"])},
    "csv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download as CSV (.csv)"])},
    "clientInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show info"])},
    "viewMode": {
      "grid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grid"])},
      "table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Table"])}
    },
    "backToLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to login"])},
    "detachSocial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deactivate social login"])}
  },
  "client": {
    "amountDevices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["clients"])},
    "config": {
      "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alert"])},
      "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
    },
    "dhcpInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setting a fixed/static IP for a device will make sure the device always get the same IP-address. Changing the address will require a reboot of that device or rejoining the proper wireless network."])},
    "dropdown": {
      "filterAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])}
    },
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "filter": {
      "allNetworks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All networks"])}
    },
    "guests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guests"])},
    "hasStaticIp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This device has a fixed IP."])},
    "infoIp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client IP"])},
    "infoName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client name"])},
    "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client information"])},
    "plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clients"])},
    "singular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client"])},
    "static": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserved clients"])},
    "staticIpNotMatched": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The current IP address does not match the fixed IP address. Reboot the device or reconnect to the wifi"])},
    "table": {
      "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
      "clientType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device type"])},
      "ip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ip"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hostname"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
      "vendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendor"])},
      "signal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signal"])}
    },
    "updateFixedIp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client info"])},
    "wired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wired"])},
    "wireless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wireless"])},
    "restart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will need to reboot the device or reconnect to the correct wireless network to see the changes."])},
    "connectedWith": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your device is currently connected to ", _interpolate(_named("wlan")), ", connect the device to ", _interpolate(_named("wlanToConnect"))])},
    "clientErrors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Some of your clients need your attention"])},
    "errorWlan": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your device <strong>", _interpolate(_named("device")), "</strong> is currently connected to <strong>", _interpolate(_named("old")), ". Please connect it to <strong>", _interpolate(_named("new")), "</strong></strong>"])},
    "errorIp": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The device <strong>", _interpolate(_named("device")), "</strong> has new settings, please reboot the device."])},
    "restartNeeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You made changes to this client. Please reboot it before you can continue."])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Needs attention"])},
    "informationStatic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Be carefull when changing settings. Changing an IP/network can have a big impact on your network. We are never responsible for errors due to misconfiguration."])},
    "allReservations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All configured clients with a fixed IP"])},
    "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View all configured clients on the network. Configured clients have a DHCP reservation. "])},
    "releaseReservations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configured clients"])},
    "reserved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configured clients"])},
    "createReservation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a DHCP reservation"])},
    "reservation": {
      "invalidMac": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The given MAC-address is invalid."])}
    },
    "notConnected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not connected"])}
  },
  "command": {
    "reboot": {
      "inProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reboot command given, reboot in progress..."])}
    }
  },
  "cookiepopup": {
    "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept"])},
    "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["By clicking \"Accept all cookies\" you agree to the storage of cookies on your device to improve site navigation, analyze site usage, and support our marketing efforts. Check out our ", _interpolate(_list(0)), " for more information."])},
    "decline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decline"])},
    "modal": {
      "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept all cookies"])},
      "centrum": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When you visit websites, they may store or request information in your browser. This storage is often necessary for basic functionality of the website. The storage may be used for marketing, analytics, and personalization of the site, such as saving of your preferences. Privacy is important to us, so you have the option to disable certain types of storage that are not necessary for basic website operation. Blocking categories may impact your experience on the website."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Preference Center"])}
      },
      "decline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reject all cookies"])},
      "preferences": {
        "analytics": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["These items help the website operator understand how their website is performing, how visitors are interacting with the site, and if there are any technical issues. This storage type usually does not collect information that identifies a visitor."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analytics"])}
        },
        "default": {
          "always": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Always active"])},
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["These items are necessary to enable the basic functionality of the website."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Necessary"])}
        },
        "marketing": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["These items are used to deliver advertisements more relevant to you and your interests. They may also be used to limit the number of times you see an advertisement and measure the effectiveness of advertising campaigns. Ad networks usually place them with the permission of the website administrator."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marketing"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage permission preferences by category"])},
        "personalisation": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalization"])},
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["These elements enable the website to remember choices you make (such as your user name, language or the region you are in) and provide enhanced, more personalized features. For example, a website can provide you with local weather or traffic information by storing information about your current location."])}
        }
      },
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm my preferences and close"])}
    },
    "preferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preferences"])},
    "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])}
  },
  "customer": {
    "address": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your customer address"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])}
    },
    "invoices": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An overview of your invoices"])},
      "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download invoice"])},
      "noResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>No invoices were found.</p>"])},
      "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay"])},
      "table": {
        "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
        "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice number"])},
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice date"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice status"])},
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount due"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoices"])}
    },
    "payment": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An overview of your payment methods, current subscriptions and invoices."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage your payments"])}
    },
    "plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Companies"])},
    "profile": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An overview of your customer data"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])}
    },
    "singular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
    "logo": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload your logo"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your logo"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload your logo so we can customize some pages with your logo"])}
    },
    "info": {
      "accountLocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have outstanding invoices that are already past the due date. If these remain unpaid we will be forced to take legal action. If you have any questions regarding this message, please contact us +32 3 375 60 70."])},
      "lastWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Important warning"])}
    },
    "plan": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The plan of the customer"])}
    },
    "stripe": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tools for billing"])},
      "paymentPortal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate payment portal url for the customer"])},
      "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open customer account in Stripe"])}
    },
    "create": {
      "header": {
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address details"])},
        "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company details"])},
        "invoicingAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoicing details"])},
        "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group"])},
        "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
        "plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default plan"])},
        "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create account"])}
      },
      "accountWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you disable the create account option, account will be not created. But also not linked to an existing user/account. If you want to link it to an existing user, allow it and fill in the mailaddress of the registered user."])}
    },
    "paymentMethod": {
      "toggleMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active payment method (check Stripe)"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment method"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manually set the payment method flag."])}
    }
  },
  "dashboard": {
    "details": {
      "downloadQr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download QR code"])}
    },
    "devices": {
      "noap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No wireless network found"])},
      "nogateway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No gateway found"])},
      "noswitch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No switch found"])}
    },
    "invoices": {
      "noResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All invoices are paid."])}
    },
    "location": {
      "notInstalled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not installed yet"])}
    },
    "locations": {
      "allLocationsInstalled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All networks installed in all locations."])},
      "noResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All networks in all locations are OK."])}
    },
    "notification": {
      "allSystemsDown": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your amplr network is offline. Do you have electricity? Yes? Please call <span class=\"has-text-weight-bold\">", _interpolate(_named("provider")), "</span> on <a href=\"tel:", _interpolate(_named("phone")), "\">", _interpolate(_named("phone")), "</a>!"])},
      "allSystemsRunning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your amplr network is fully operational. Your internet connection is provided by <span class=\"has-text-weight-bold\">", _interpolate(_named("providerName")), "</span>."])},
      "degradedSystem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Part of your network is not operational."])},
      "failOverActive": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Failover is active. This means that only cash register traffic, payments and Spotify can be used. Any guest networks or other networks will be temporarily unavailable. Your connection is SIM based and is connected to the ", _interpolate(_named("carrier_name")), " network."])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are retrieving information from this location."])},
      "readyForInstallation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This location is not ready for use yet. We are waiting for the installation to be completed."])},
      "noHardware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We couldn't findn any hardware connected to your system."])},
      "powerOutage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<span class=\"has-text-weight-bold\">", _interpolate(_named("source")), "</span> reported an electricity outage in your area. The outage started on <span class=\"has-text-weight-bold\">", _interpolate(_named("startDate")), "</span> and should last until <span class=\"has-text-weight-bold\">", _interpolate(_named("endDate")), "</span>."])}
    },
    "publicNetworks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is a public network for guests."])},
    "titles": {
      "ap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wireless"])},
      "clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connected clients"])},
      "deviceGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Systems"])},
      "gateway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gateway"])},
      "invoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unpaid invoices"])},
      "locationDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
      "locations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locations that require attention"])},
      "locationsReadyForInstall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locations ready for installation"])},
      "privateNetworks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Private networks"])},
      "publicNetworks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public networks"])},
      "switch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switches"])},
      "wan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internet"])}
    },
    "addRow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add row"])},
    "financials": {
      "yearToDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year to date"])},
      "fromTarget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To complete"])},
      "currentMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current month"])},
      "avgDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per day"])},
      "lastMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last month"])},
      "mrr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MRR"])}
    },
    "wlan": {
      "ppsk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This network uses PPSK (Private Pre-shared Key). Passwords can be set per user/company in the tenants tab."])}
    },
    "tooltips": {
      "deviceGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Systemgroups are a collection of network connected devices like computers, speakers, alarm, camera and so on. Create custom groups to monitor the connection."])}
    },
    "topology": {
      "gateway": {
        "fixedLine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed line"])},
        "cellular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4G/5G"])},
        "wanIp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public IP"])},
        "virtualWan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virtual WAN"])}
      },
      "unknownPublicIp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown"])}
    },
    "wifi": {
      "privateNetworks": {
        "makeHidden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make network hidden"])},
        "tooltip": {
          "makeHidden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Making a network hidden results in not seeing the wifi network when scanning. You can connect to a hidden network by entering the network details manualy"])},
          "broadcast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A visible network can be seen by devices while scanning for wifi networks. When you want to make this network hidden, disable broadcast. Connecting to a hidden network requires you to manually enter the wifi name and password."])}
        },
        "broadcast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make this network visible (broadcast)"])}
      }
    },
    "privateNetworks": {
      "isHidden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hidden network"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit wifi settings"])},
      "label": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wifi name"])}
      }
    }
  },
  "datepicker": {
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This month"])},
    "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today"])},
    "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This year"])}
  },
  "error": {
    "401": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are unauthorized to view this content"])},
    "404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not found"])},
    "500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error on the server."])},
    "apiThrottled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have recently already requested a resetlink."])},
    "auth": {
      "invalidResetToken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The reset token is invalid"])},
      "sameAsPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The passwords don't match"])},
      "userNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This account doesn't exist"])},
      "wrongPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password is not correct"])},
      "googleLoginFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A general login with Google occured. Please try again or log in with your email and password."])}
    },
    "deviceOffline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device is not connected"])},
    "file": {
      "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This file is too large to upload. The maximum supported file size is 500 kb."])}
    },
    "generalError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong."])},
    "image": {
      "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This image is too large to upload. The maximum supported file size is 5 mb."])}
    },
    "invalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email validation failed. Enter a valid email address."])},
    "invalidPublicId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid public id"])},
    "messageLoadingLong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the loading takes longer than usual..."])},
    "noClientsFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No clients found."])},
    "noDeviceGroupsFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No device groups found."])},
    "noDevicesFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No devices found."])},
    "noInvitesFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No invitations found"])},
    "noPrivateNetworkFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No private networks found"])},
    "noPublicId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No public id"])},
    "noPublicNetworkFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No public networks found."])},
    "noSitesFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No sites found."])},
    "noUserDataFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No data found."])},
    "noUsersFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No users found"])},
    "portal": {
      "invalidPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password is not correct."])}
    },
    "portalClient": {
      "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We couldn't find your email address. This means that we have not stored any data about you."])}
    },
    "sessionExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your session expired. You will need to login again."])},
    "sitesNnone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No sites found"])},
    "userExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitations not sent.<br/>Following users cannot be invited. Remove them from the list and try again."])},
    "value": {
      "isEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The value can't be empty"])},
      "notDigits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The value is not valid"])},
      "notInRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The value is not in range"])}
    },
    "vat": {
      "unique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This VAT number already exists"])},
      "exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This VAT number already exists"])},
      "stripeError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stripe error: incorrect EU vat number provided. Please use a legal VAT number."])}
    },
    "noPermissionsSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select at least 1 permission for each column."])},
    "noTenantsFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No tenants found"])},
    "tenants": {
      "csv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The csv was not in the correct format"])}
    },
    "loginError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, your login attempt has failed. Please check your username and password and try again."])},
    "2fInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The provided two factor authentication code was invalid."])},
    "config": {
      "configId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a config"])},
      "choosePeplinkSn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in the peplink serial number"])},
      "chooseController": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a controller"])},
      "chooseVendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a vendor"])},
      "wlansName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in all names"])},
      "plan": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Based on the amount of devices you should select following plan: ", _interpolate(_named("plan")), " or higher"])}
    },
    "partners": {
      "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No partners found"])}
    },
    "noAccessFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No access found"])},
    "noLocationsFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No locations found"])},
    "noGroupsFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No groups found"])},
    "no": {
      "link": {
        "access": {
          "found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We couldn't register you as the link you used is no longer valid."])}
        }
      }
    },
    "invalidSubscriptionId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid subscription ID."])},
    "subscriptionNotLinkedToCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The provided subscription ID is not associated with the customer linked to this location."])},
    "couldNotConnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not connect to the controller. Please check the details like url/ip, user and password in the info tab."])},
    "noUserFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No user can be found with this Google address. Please try again."])},
    "google": {
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A general error occured when logging in with your Google account. Please try again or use your email and password."])},
      "noUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No user found linked to this Google account. Please use an existing account or login with email and password."])}
    },
    "microsoft": {
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A general error occured when logging in with your Microsoft account. Please try again or use your email and password."])}
    },
    "googleAlreadyRegistered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Google email is already registered to another user in the platform."])},
    "googleIdLinkedToAnotherAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Google account is already linked to another user in the platform. "])},
    "microsoftAccountExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is already a social login attached to this account. Please use your Microsoft account to log in."])},
    "googleAccountExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is already a social login attached to this account. Please use your Google account to log in."])},
    "useSocialLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login with email and password is disabled. Please your attached Google or Microsoft login."])}
  },
  "errorPage": {
    "404": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hmmm... this page doesn't exist anymore or is moved to another location. Let us take you back!"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not found"])}
    },
    "maintenance": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We apologize for the inconvenience. We will be back online soon."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The application is currently offline for maintenance."])}
    }
  },
  "form": {
    "field": {
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street"])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
      "configName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Config name"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
      "customerAlias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alias"])},
      "deviceGroupName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System group name"])},
      "downloadSpeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download speed"])},
      "downloadSpeedFailover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download speed of the failover"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
      "endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End date/time"])},
      "endDatePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose date/time"])},
      "freeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comments"])},
      "ip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP-address"])},
      "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["language"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "nameInstaller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of the installer"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
      "passwordConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm password"])},
      "passwordNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password"])},
      "peplinkOrg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peplink org"])},
      "peplinkSerial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peplink serial"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
      "provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provider"])},
      "providerid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provider ID"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
      "searchNetworkDevice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search a network device"])},
      "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start date/time"])},
      "startDatePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose date/time"])},
      "uploadSpeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload speed"])},
      "uploadSpeedFailover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload speed of the failover"])},
      "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT number"])},
      "verifyBeforeDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat label above"])},
      "website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website"])},
      "zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postal code"])},
      "locationType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location type"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
      "simNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sim number"])},
      "statusLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Label"])},
      "placeholder": {
        "simNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["00000000000000000000"])},
        "internetType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose an internet type"])},
        "controllerIp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0.0.0.0"])},
        "vendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select vendor"])},
        "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doe"])},
        "pppoeUsername": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PPPOE username"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*********"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a file"])},
        "statusLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lightspeed"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["info", "@", "amplr.be"])},
        "website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.amplr.be"])},
        "partners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a partner"])},
        "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lokeren"])},
        "zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9160"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieptestraat 2"])},
        "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BE0768.611.469"])},
        "downloadSpeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["50"])},
        "uploadSpeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["50"])},
        "ip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["127.0.0.1"])},
        "deviceGroupName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lightspeed"])},
        "venueName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["amplr"])},
        "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["amplr"])},
        "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["John"])},
        "searchProducts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for products"])},
        "searchLocations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search location"])},
        "legalEntity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a legal entity"])},
        "subscriptionId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Give the subscription id: sub_xxxxx"])},
        "subscriptionItemId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Give the subscription item id: si_xxxxx"])},
        "controllerAlias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My first cloudcontroller"])},
        "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+3247777777"])},
        "room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room/space"])},
        "mailchimpAudiences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose your Mailchimp audience"])},
        "ipSuffix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["199"])}
      },
      "controllerIp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controller IP"])},
      "amountGateways": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount gateways"])},
      "amountAp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount AP"])},
      "amountSwitch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount switch"])},
      "pppoeUsername": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PPPOE username"])},
      "pppoePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PPPOE password"])},
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
      "venueName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location name"])},
      "partners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partners"])},
      "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firstname"])},
      "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number"])},
      "box": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Box"])},
      "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company name"])},
      "sector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a sector"])},
      "spaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spaces"])},
      "subnet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All networks"])},
      "legalEntity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal entity"])},
      "placholder": {
        "downloadSpeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["20"])}
      },
      "subscriptionId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stripe subscription id"])},
      "connectionType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connection type"])},
      "hasFailover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Has failover"])},
      "subscriptionItemId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription Item ID"])},
      "vendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select controller vendor"])},
      "tooltip": {
        "controllerUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provide the full controller url with the port if needed. Ex: https://yourdomain.com:8443 or https://193.12.123.12:8443."])},
        "controllerAlias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Give an alias to your controller for internal reference. If left blank a random string will be generated."])}
      },
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
      "controllerUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controller URL"])},
      "controllerAlias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controller alias"])},
      "replyTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email reply to address"])},
      "room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room/space"])},
      "providerSatisfaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provider satisfaction"])},
      "providerSatisfactionFreeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why is the customer satisfied"])},
      "providerIssuesReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a reason"])},
      "selectFollowUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select follow up"])}
    },
    "fileInput": {
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove file"])}
    },
    "select": {
      "selectDeviceGroupType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of system"])}
    },
    "label": {
      "selectNetwork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Which network does this device belongs to?"])},
      "clientName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Give an alias to the device"])},
      "useStaticIp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use a static/fixed IP"])},
      "images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Images"])},
      "rememberMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remember me"])},
      "hasFailover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location will have a failover connection"])},
      "connectionTypes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connectiontype"])},
      "uniformBilling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link specific item from subscription"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venue name"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
      "postalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postal code"])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
      "emailAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
      "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
      "contactPerson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact person"])},
      "chooseProvider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select current internetprovider"])},
      "posSystem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["POS system"])},
      "canUseFailover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uses delivery/cloud services/..."])},
      "terrace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Has terrace"])},
      "wantsOffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wants a quotation"])},
      "venueSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a size"])},
      "walkin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This was a walk in"])},
      "notInterested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not interested"])},
      "config": {
        "failover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failover solution"])},
        "router": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Router"])},
        "isPeplink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peplink router"])},
        "switch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch"])},
        "ap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access Point"])},
        "amountVenues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount of venues"])},
        "plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service plan"])},
        "installCat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Install category"])},
        "connectionType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internet connection"])},
        "extraPeplink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount special Peplink licence (bruto)"])},
        "hasSpecialPeplink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Has special Peplink licence"])},
        "maxOneOff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximun amount for one off cost"])},
        "fixedIp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed IP"])}
      },
      "variantCaas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is Connectivity-as-as-Service"])},
      "visit": {
        "interested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interested"])},
        "marketingOptIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consent for mails/marketing"])},
        "knowsAmplr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knows amplr already"])}
      },
      "router": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Router"])},
      "switch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch(es)"])},
      "ap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access points"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
      "needsFollowUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Needs follow up"])},
      "contactPersonPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact person phone number"])},
      "contactPersonEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact person email"])},
      "venueName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Venue name"])},
      "deviceName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device name"])},
      "selfOrdering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Has kiosk"])},
      "takeOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Has take out/delivery"])},
      "satisfactionScore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Satisfaction rate"])},
      "providerIssuesReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason why unhappy"])},
      "followUpMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow up action"])},
      "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What is the role of the spokesperson"])},
      "nodeName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Node name"])},
      "nodeDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Node description"])},
      "mac": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAC-address"])}
    },
    "placeholder": {
      "selectNetwork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select network"])},
      "connectionType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a connection type"])},
      "posSystem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a POS system"])},
      "venueSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venue size"])},
      "config": {
        "connectionType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a connection type"])},
        "plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a SLA plan"])},
        "installCat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Define the install category"])}
      },
      "sales": {
        "loadExisting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load an existing calculation"])}
      },
      "venueName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bistro A Laisse"])},
      "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a role"])}
    },
    "noResults ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No results found. Try again."])}
  },
  "general": {
    "clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clients"])},
    "connectionRestored": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have detected an active internet connection again on your device. You can continue to use amplr.cloud! Press refresh or wait for the window to disappear."])},
    "customers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customers"])},
    "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groups"])},
    "locations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locations"])},
    "noConnection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are not connected to the Internet. Make sure you have an active Internet connection to use amplr.cloud."])},
    "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offline"])},
    "online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
    "integrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrations"])}
  },
  "group": {
    "plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groups"])},
    "singular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group"])},
    "partners": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partners"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can add or remove your partners here"])}
    }
  },
  "groupProfile": {
    "address": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The address of your group"])}
    },
    "profile": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An overview of your group details"])}
    }
  },
  "history": {
    "table": {
      "ap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access Points"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
      "failover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failover"])},
      "gateway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gateway"])},
      "noFailover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No failover"])},
      "noResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>No data found.</p><p>Adjust your search term, you might have better luck.</p>"])},
      "switch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switches"])}
    }
  },
  "info": {
    "listening": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listening..."])},
    "loadingPeplinkGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please wait while we are loading all Peplink groups related to the selected organisation."])},
    "enableTwoFactor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secure your account with two factor authentication. Use the Google Authenticator app or your prefered password manager."])},
    "twoFactorActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account is secured with two factor authentication."])}
  },
  "location": {
    "address": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The address of the location"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])}
    },
    "deviceGroups": {
      "addDeviceGroupTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a new system group"])},
      "addFirstDeviceGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create your first group"])},
      "addMoreDevices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add more devices"])},
      "connectedClients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active network devices"])},
      "devices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add devices to your group"])},
      "devicesError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose at least 1 device to add to your group"])},
      "editModal": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can change the name and type of your system group"])},
        "deviceBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add or delete devices from your group"])},
        "deviceTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update group devices"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group name"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System group info"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group type"])}
      },
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Give a name to your group"])},
      "newDeviceGroup": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cool! We have created a new system group (", _interpolate(_named("name")), "), so you can check whether the devices in the system group are online."])},
      "newDeviceGroupList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System group devices"])},
      "notification": {
        "deviceGroupCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New device group has been created"])},
        "deviceGroupUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device group has been updated"])}
      },
      "step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add group info"])},
      "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add devices"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a type for your group"])},
      "confirmDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this device group?"])}
    },
    "installation": {
      "bridge": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is there a bridge present?"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make a choice"])}
      },
      "failover": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is there a failover present?"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make a choice"])}
      },
      "guest_wifi": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is the guest wifi OK?"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make a choice"])}
      },
      "images": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload some images of the installation"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload images"])}
      },
      "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
      "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])}
    },
    "plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locations"])},
    "singular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
    "updateWlan": {
      "form": {
        "fields": {
          "verifyBeforeSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm to save."])}
        }
      },
      "notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you change the settings of this wifi network, all already connected devices are going to lose their connection and need to be reconnected with the new settings."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change wifi settings for: "])},
      "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you still want to change the settings? Confirm by entering the wifi network name."])}
    },
    "connection": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connection"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the connection for the location"])}
    },
    "create": {
      "header": {
        "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
        "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location name"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internet connections"])},
        "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locationtype"])}
      },
      "customerAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use customer address as location address"])}
    },
    "createSubscription": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create subscription"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a new stripe subscription for this location. You will be redirected to stripe."])}
    },
    "createInvoice": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create invoice"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create an invoice for one off costs or interventions."])}
    },
    "billing": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription details"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find more details on the location subscription here."])},
      "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
      "serviceBilledButNotActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service is billed but not active on location"])},
      "serviceNotBilled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service is active on location but not billed."])}
    },
    "plan": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service plan"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set the service plan for this location."])}
    },
    "config": {
      "unifiOs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is a Ubiquiti OS location"])}
    },
    "topology": {
      "vpn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fusionhub"])},
      "tooltip": {
        "vpn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fusionhub connections that are available between this location and the hub."])}
      },
      "client": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "ip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP-address"])},
        "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client details"])},
        "connectionDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connected trough"])},
        "connectedTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connected to"])},
        "connectedPort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Port"])},
        "wired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch"])},
        "wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wireless"])}
      }
    }
  },
  "locationProfile": {
    "commands": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Special commands to execute on your network"])},
      "reboot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reboot all network appliances"])},
      "rebootNetwork": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When you reboot all network devices, there is temporarily no Internet at this location. Restarting these devices may take several minutes. Are you sure?"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reboot all network appliances"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Command center"])}
    },
    "hours": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your opening hours here"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opening hours"])}
    },
    "info": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra details regarding you venue"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])}
    },
    "qr": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate a public QR code"])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A public QR code allows your employees to quickly retrieve the status of the network. Incorporate the QR code into your procedures or place it on site."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support QR"])}
    },
    "service": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Based on your service plan, you can use the following services"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available services"])}
    }
  },
  "locations": {
    "payment": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An overview of your payment methods, current subscriptions and invoices."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage your payments"])}
    },
    "table": {
      "noResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>No locations found.</p><p>Try your luck and adapt the search term.</p>"])},
      "qr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download QR-code"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
      "siteName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locatie"])},
      "sitePlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])}
    },
    "installed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
    "notInstalled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not installed"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])}
  },
  "mainNav": {
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
    "admin": {
      "config": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Config"])},
      "controllers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controllers"])},
      "payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payments"])},
      "inventory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inventory"])},
      "zerotier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zerotier"])}
    },
    "app": {
      "b2c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview"])},
      "customers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Companies"])},
      "portal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portal"])}
    },
    "button": {
      "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
      "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])}
    },
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
    "partners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partners"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscriptions"])},
    "sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales"])},
    "refferal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refferal Bonus"])},
    "referral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referral bonus"])}
  },
  "modalAdminAddCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add customer"])},
  "mydata": {
    "download": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you wish to download your data, please enter your email address below."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download data"])}
    },
    "optout": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you want us to delete your data, please enter your email address below."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete data"])}
    }
  },
  "nav": {
    "account": {
      "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])}
    },
    "admin": {
      "companies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customers"])},
      "config": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Config"])},
      "controllers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controllers"])},
      "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
      "locations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locations"])},
      "payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payments"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
      "sales": {
        "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview"])},
        "createVisit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create visit"])},
        "configCalculator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Config calculator"])}
      },
      "inventory": {
        "inventory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inventory"])},
        "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMPLR Products"])},
        "logs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logs"])},
        "allocatedCaas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allocated CaaS"])}
      }
    },
    "button": {
      "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
      "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])}
    },
    "customer": {
      "b2b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["B2B"])},
      "b2c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["B2C"])},
      "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group"])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
      "invoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoices"])},
      "locations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locations"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
      "titleB2c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status page"])},
      "branding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branding"])}
    },
    "dashboard": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])}
    },
    "deviceGroups": {
      "devices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devices"])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])}
    },
    "group": {
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group"])}
    },
    "locations": {
      "clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devices"])},
      "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
      "deviceGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Systems"])},
      "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History"])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
      "portal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guests"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locations"])},
      "topology": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setup"])},
      "vendors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendors"])},
      "installationReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installation Report"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status page"])},
      "tenantsManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenants"])},
      "configReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration report"])},
      "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comments"])},
      "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing"])}
    },
    "portal": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portal"])}
    },
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
    "users": {
      "invites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invites"])},
      "table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])}
    },
    "partner": {
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])}
    },
    "invites": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invites"])}
    },
    "access": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access"])}
    },
    "integrations": {
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview"])}
    }
  },
  "noAccess": {
    "body": {
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfortunatly, you don't have access to this page."])},
      "plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfortunatly, you don't have access to this page. Upgrade your plan in order to see this page."])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No access."])},
    "titleNoPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No access. Please enter your password."])}
  },
  "noPlan": {
    "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must have a higher plan to access this page. Contact us for the possibilities."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade your plan"])}
  },
  "notPayed": {
    "body": {
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account is temporarly dissabled. Please contact the admin of your company to complete the payment info."])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No access"])}
  },
  "notification": {
    "customer": {
      "noPaymentMethod": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["No payment method is currently set for ", _interpolate(_named("customerName")), ". Avoid automatic disconnection of your internet and services."])},
      "paymentMethodSet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have an active payment method. If you would like to change it, go to the payment page."])}
    },
    "demoMode": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hi ", _interpolate(_named("name")), ", you are viewing amplr.cloud in demo mode. No worries, no features are hidden! If you have any questions related to the platform. Please reach out at support", "@", "amplr.be!"])},
    "snooze": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snooze setting updated"])},
    "imposter": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You are currently impersonating <strong>", _interpolate(_named("name")), "</strong>. If you want to leave imposter mode, push the stop button!"])},
    "referral": {
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thanks for referring! We will get in touch with your lead and keep you posted. You can add another referral if you like!"])}
    },
    "exportStarted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export started. Please check your mailbox for the finished result."])}
  },
  "openingHours": {
    "createBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create opening hours for this location. You can use them to put your guest portal offline when the business is closed."])},
    "error": {
      "timePerDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter an opening and closing time for each open day."])},
      "times": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter an opening and closing time."])}
    },
    "everyDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Every day same opening and closing time"])},
    "fri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fri"])},
    "mon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon"])},
    "noResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no opening hours set yet"])},
    "sat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sat"])},
    "sun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sun"])},
    "thu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thu"])},
    "tue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tue"])},
    "wed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wed"])}
  },
  "pagination": {
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "prev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])}
  },
  "permissions": {
    "customer": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])}
    },
    "customer_read": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gives the user permission to view company data."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Company"])}
    },
    "customer_update": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gives the user permission to edit company information."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Company"])}
    },
    "group": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group"])}
    },
    "group_read": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gives the user permission to view group data."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View group"])}
    },
    "group_update": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gives the user permission to edit group data."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit group"])}
    },
    "invoices": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoices"])}
    },
    "invoices_read": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gives the user permission to view billing information."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Invoices"])}
    },
    "invoices_update": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gives the user permission to edit billing information."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Invoices"])}
    },
    "location": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])}
    },
    "location_read": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gives the user permission to view location data."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View location"])}
    },
    "location_update": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gives the user permission to edit location information."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change location"])}
    },
    "portal": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portal"])}
    },
    "portal_read": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gives the user permission to view portal data."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View portal"])}
    },
    "portal_update": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gives the user permission to edit portal data."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change portal"])}
    },
    "users": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])}
    },
    "users_create": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gives the user permission to add users to specific groups, companies, or locations."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Users"])}
    },
    "users_delete": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gives the user permission to delete users"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Users"])}
    },
    "users_read": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gives the user permission to view user data."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Users"])}
    },
    "users_update": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gives the user permission to edit user data."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Users"])}
    },
    "noAccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't have permissions to perform actions."])}
  },
  "policy": {
    "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>By connecting to this Guest Network, you acknowledge that you have read, understood and agree to be bound by this Agreement. This Guest Network is provided by the Owners and is at their sole discretion. Your access to the network may be blocked, suspended or terminated at any time for any reason. You agree not to use the wireless network for any unlawful purpose and you assume full responsibility for your actions. The Wireless Network is provided 'as is' without warranties of any kind, either express or implied.</p><p>To establish the connection of this guest network, the mac address of the client to be connected is used and stored.</p><p>AMPLR BV can in no way be held liable for the use of this wireless network.</p><p>AMPLR BV may retain data if requested when logging on to the network. Under no circumstances will this data be used for marketing purposes, unless the user gives permission (opt-in).</p><p>If the user wishes to view or edit or delete their data, they can contact us by emailing dataprotection[at]amplr.be</p>"])},
    "optout": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you wish to view or delete your data from our database, click on the button below."])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My data"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View and delete data"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms & conditions"])},
    "titleOffice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Office"])}
  },
  "portal": {
    "body": {
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public wifi is currently unavailable. Please try again later."])},
      "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are currently closed."])},
      "policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By clicking connect you agree with our"])},
      "policyLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms and conditions"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<a href=\"https://www.amplr.be\" target=\"_blank\" class=\"has-text-primary has-text-weight-bold\">amplr</a> offers free, secure and fast\nwifi at this venue. Press connect and start surfing."])},
      "trusted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1000+ locations use <a href=\"https://www.amplr.be\" target=\"_blank\" class=\"has-text-primary has-text-weight-bold\">amplr</a>"])},
      "connecting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Securing your connection"])}
    },
    "client": {
      "email": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in your e-mail address"])}
      },
      "emailOptIn": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My data may be used for marketing purposes."])}
      },
      "firstname": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in your first name"])}
      },
      "lastname": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fill in your last name"])}
      },
      "phonenumber": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in your phone number"])}
      },
      "premiumWifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Opting in will result in faster wifi)"])},
      "forceOptIn": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To make use of the free wifi I acknowledge that my data may be used for marketing purposes."])}
      },
      "emailOptInPiggy": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My data may be used for marketing purposes and loyalty."])}
      }
    },
    "overview": {
      "allTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All-time connected guests"])},
      "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All connected guests today"])},
      "todayOptIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marketing opt-in today"])},
      "allTimeOptIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marketing opt-in all time"])}
    },
    "settings": {
      "advancedSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced settings"])},
      "analytics": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a Google Tag Manager or Facebook Pixel ID to add tracking to your portal"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analytics"])}
      },
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention, you are editing the general customer settings"])},
      "data": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configure here which data you want to collect from the user."])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View data"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collect data"])}
      },
      "design": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update images, colors and text of your portal."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configure portal"])}
      },
      "form": {
        "analyticsFacebook": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook Pixel ID"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["123456789123456"])}
        },
        "analyticsGoogle": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google Tag Manager ID"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GTM-ABC1234"])}
        },
        "analyticsPrivacyPolicy": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy policy URL"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.amplr.be/privacy-policy"])}
        },
        "background": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Background image (jpg, png)"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a file"])}
        },
        "bgColor": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Background color"])}
        },
        "body": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adapt the body text"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The text for the body"])}
        },
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configure page"])},
        "buttonText": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adapt the button text"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The text for the button"])}
        },
        "client": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If unchecked, the user can connect without entering data."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Which data do you want to collect from the user?"])}
        },
        "color": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a color"])}
        },
        "customer": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use customer settings"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can follow company settings so you don't have to setup a portal for each location."])}
        },
        "disabled": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place portal temporarily offline"])}
        },
        "disabledText": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adapt the temporarily offline text"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The text for when the portal is temporarily offline"])}
        },
        "duration": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration (min)"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ex. 30"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Define how long guest can stay connected after authentication. "])}
        },
        "logo": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo (svg, jpg, png)"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a file"])}
        },
        "mainColor": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main color"])}
        },
        "offlineText": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adapt the offline text"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The text for when the portal is offline"])}
        },
        "password": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a password"])}
        },
        "passwordToggle": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use a password to login"])}
        },
        "schedule": {
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change openingshours"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place portal offline outside opening hours"])}
        },
        "secondaryColor": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accent color"])}
        },
        "secondaryTextColor": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accent text color"])}
        },
        "textColor": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text color"])}
        },
        "toggle": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalized portal"])}
        },
        "url": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Landingspage"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ex. https://www.amplr.be"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Define where guests need to go after authentication. This can be your website or other landing page."])}
        },
        "webhookEndpoint": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhook URL"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://yourcustomwebhook.zapier.com"])}
        },
        "webhookSecret": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhook shared secret"])}
        },
        "primaryColor": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primary color"])}
        },
        "forceOptIn": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Force opt-in for marketing"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By enabling this function, clients that want to connect need to check the box regarding marketing purposes."])}
        },
        "forceEmailValidation": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By using force email validation, all emailaddresses will be validated by a thirth-party provider to check if the used address is legitimate."])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable email address validation"])}
        },
        "strictEmailValidation": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strict validation implies that only addresses that have a healthscore of 100 will be allowed. If the score is lower but still deliverable, the user will receive an error because strict validation is enabled."])}
        },
        "strictValidation": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Force strict validation"])}
        }
      },
      "general": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update general settings of your portal."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])}
      },
      "login": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose here how you want a user to log in to your public WiFi. If nothing selected, the user can connect without authentication."])},
        "facebook": {
          "input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in your Facebook API key"])},
          "inputSecret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in your Facebook API secret key"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook API key"])},
          "placeholderSecret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook API secret key"])},
          "toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show Facebook login button"])}
        },
        "google": {
          "input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in your Google API key"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google API key"])},
          "small": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note: Google login does not work on iOS captive portal"])},
          "toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show Google login button"])}
        },
        "linkedin": {
          "input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in your LinkedIn API key"])},
          "inputSecret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in your LinkedIn API secret key"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LinkedIn API key"])},
          "placeholderSecret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LinkedIn API secret key"])},
          "toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show LinkedIn login button"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentication"])}
      },
      "preview": {
        "desktop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["desktop"])},
        "mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mobile"])},
        "tablet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tablet"])}
      },
      "schedule": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Put portal temporarily offline or put offline outside the opening hours"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offline"])}
      },
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your changes where saved."])},
      "tabs": {
        "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
        "fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["French"])},
        "layout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Layout"])},
        "nl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dutch"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])},
        "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["German"])}
      },
      "webhook": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make use of webhooks to trigger external applications to do something."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhooks"])}
      },
      "customPortal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At this moment, an amplr-branded page is used as guest wifi portal. Enable the custom portal to use your branding."])},
      "integrations": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrations"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get the most out of your guest wifi by using our integrations."])}
      }
    },
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The settings are saved."])},
    "table": {
      "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
      "block": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block user"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
      "device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
      "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firstname"])},
      "ip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP-address"])},
      "mac": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mac address"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "noResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>No users found.</p><p>Try your luck and adapt the filters.</p>"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search on email or mac address"])},
      "unblock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unblock user"])},
      "mailchimp_sync": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mailchimp status"])}
    },
    "integration": {
      "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive"])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
      "message": {
        "piggy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join the <b>loyalty</b> program and receive perks immediately."])}
      }
    },
    "integrations": {
      "smartendrModal": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smartendr"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To use this integration, you must have an active Smartendr subscription. "])},
        "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable this integration"])},
        "openDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Smartendr"])},
        "step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable this integration"])},
        "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paste the API-key in your Smartendr account"])},
        "step3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paste this unique ID in the Smartendr dashboard to identify the location"])}
      },
      "apiKey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This API key will be only displayed once"])},
      "locationId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The location ID is unique for every location"])},
      "piggyModal": {
        "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your API key, you can find this in the Piggy dashboard"])},
        "openDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Piggy"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piggy"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To use this integration you need an active subscription with Piggy."])}
      },
      "mailchimpModal": {
        "openDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Mailchimp"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mailchimp"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You need an active Mailchimp subscription to use this integrations."])},
        "step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable this integration"])},
        "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable this integration"])},
        "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paste your API-key, this can be found in the Mailchimp dashboard"])},
        "step3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select your audience"])}
      }
    },
    "error": {
      "mailNotvalidated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops! It looks like that email address is playing hide-and-seek. Try again with a real one, please! "])}
    }
  },
  "scheduler": {
    "buttons": {
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add periode"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])}
    },
    "duration": {
      "always": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Always"])},
      "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["time(s) in total"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration"])},
      "until": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Until"])}
    },
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
    "repeat": {
      "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily"])},
      "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly"])},
      "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't repeat"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat"])},
      "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weekly"])},
      "yearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yearly"])}
    },
    "until": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Until"])}
  },
  "service": {
    "customGuestPortal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branded guest wifi"])},
    "emailSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail support"])},
    "nextDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next day intervention"])},
    "phoneSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone support"])}
  },
  "status": {
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
    "notification": {
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are here to help. You can get in touch by phone"])}
    },
    "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offline"])},
    "online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online"])},
    "standby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standby"])},
    "toDeploy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not installed"])},
    "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Getting location details..."])},
    "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is the status of your internet and wifi solution at:"])},
    "issues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Having issues with something?"])},
    "problems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other problem?"])},
    "urgent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urgent support needed?"])},
    "buttons": {
      "amplr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get in touch with amplr"])}
    },
    "settings": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configure your status page"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The status page is the page your employees get when scanning the amplr QR code. You can add all the different support info to this page."])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configure page"])},
      "paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to the designer tool for your status page by clicking the button below"])},
      "noButtons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You haven't configured any contact buttons yet"])},
      "addButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add contact button"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Label"])},
      "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You need to publish your changes, otherwise the changes will not be reflected on live"])}
    },
    "gettingDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Getting details..."])},
    "notInstalled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not installed"])},
    "notConfigured": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not configured"])},
    "reportMissing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Config report"])},
    "liveLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installed"])},
    "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archived"])},
    "deviceGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your active systems"])},
    "deviceGroupOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All devices in this systemgroup are online!"])},
    "deviceGroupWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Some devices in this systemgroup are not connected at this moment. Please take a look!"])},
    "deviceGroupOffline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None of the devices in the systemgroup apear to be online. This has a major impact. Please check the group."])},
    "deviceGroupList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device overview"])},
    "deviceOffline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device offline"])},
    "deviceGroupOfflineInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This device is either not connected to a power source or not connected to the internet via a cable or wifi. Please check the connections. Identify the device based on the MAC address above."])}
  },
  "success": {
    "admin": {
      "config": {
        "added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The configuration is added."])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The configuration is deleted."])}
      },
      "customer": {
        "added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The customer is added."])},
        "addedCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The customer has been added to the group."])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The customer is deleted."])},
        "edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The customer has been edited."])},
        "locationCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The location is added to the customer."])},
        "userAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The user is added to the customer."])}
      },
      "group": {
        "added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The group is added"])},
        "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The group has been updated"])}
      },
      "location": {
        "configCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The config has been saved"])},
        "configUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The config is updated"])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The location is deleted."])},
        "infoUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The changes have been saved."])},
        "installFinished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The changes have been saved."])},
        "updatedVendors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The changes have been saved."])},
        "configReportCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The report has been saved"])},
        "commentAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your comment has been successfully added"])},
        "commentUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your report has been updated"])},
        "commentDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The report has been deleted"])},
        "controllerLinksCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The controllers are configured"])}
      },
      "userDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The user has been deleted."])},
      "userLinkDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The user has been deleted."])},
      "partner": {
        "added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The partner has been added"])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The partner has been deleted"])},
        "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The partner has been updated"])},
        "usersAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The users has been invited"])},
        "linkDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The invite has been deleted"])},
        "userDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The user has been deleted"])},
        "accessAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The access was added"])},
        "locationDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The location has been deleted"])},
        "customerDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The customer has been deleted"])},
        "groupDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The group has been deleted"])}
      }
    },
    "auth": {
      "passwordReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password succesfully changed. You can now log in."])},
      "passwordResetLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check your mailbox for the resetlink."])}
    },
    "customer": {
      "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info succesfully updated"])},
      "logoSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The logo has been saved"])}
    },
    "portalClient": {
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your data has been deleted."])},
      "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your data will be downloaded in a few moments"])},
      "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We've send you an email. Check your inbox."])}
    },
    "user": {
      "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copied to clipboard"])},
      "passwordChanged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password succesfully updated"])},
      "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The changes are succesfully updated"])}
    },
    "users": {
      "userEdited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The changes are saved"])},
      "invited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The invitation has been sent"])}
    },
    "clientSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The settings of the client are succesfully updated."])},
    "clientDhcpRemoved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We removed the fixed ip from this client."])},
    "savedDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your settings are saved"])},
    "statusButtonSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your settings are saved"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully copied!"])},
    "partner": {
      "added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The partner was addded"])},
      "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The partner has been removed"])}
    },
    "referral": {
      "added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your referral has been sent"])}
    },
    "poeCycled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cycled PoE power!"])},
    "portChange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Port changes applied!"])},
    "branding": {
      "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changes saved"])},
      "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branding created"])}
    },
    "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings saved"])}
  },
  "support": {
    "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hmmm... We notice some problems. Contact us!"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call us"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+32 3 375 60 70"])}
  },
  "tooltip": {
    "portal": {
      "webhooks": {
        "endpoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The URL that receives the request. Tools like zapier.com or typedhook.com offer webhook services to integrate with your CRM or other automation tooling."])},
        "secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The secret is sent in the request header ('amplr-wh-secret'), use it to verify if the incoming request is legitimate."])}
      }
    },
    "portalTab": {
      "markingOptIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clients that connected to the guest wifi and choose to opt-in for marketing."])},
      "allToday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All connected clients for today, also the ones that not opted-in for marketing."])},
      "allTimeOptIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All clients that opted-in for marketing since the start."])},
      "allTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All-time connected clients, also the ones that not opted-in for marketing."])}
    }
  },
  "userProfile": {
    "address": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your profile address"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])}
    },
    "profile": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A overview of your profile data"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])}
    },
    "security": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit sensitive data here"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security"])}
    },
    "setupTwoFactor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setup two factor authentication"])},
    "twoFactorScan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan the QR code with your authenticator app (Google Authenticator, 1Password, Bitwarden...). Can't scan the code? Copy and paste the URL!"])},
    "2fa": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Two-Factor Authentication"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secure your account with Two-Factor Authentication"])},
      "finishButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish setup"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Two-Factor Authentication successfully configured."])},
      "codeValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To confirm the 2FA-setup, please confirm by using your newly generated 2FA-code."])}
    },
    "socialLogin": {
      "googleActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google authentication is active. You can't login with username and password. If you want to use username and password to login, detach social login."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social login"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login to the platform by using social login like Google or Microsoft."])},
      "microsoftActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Microsoft login is active. You can't login with username and password. If you want to use username and password to login, detach social login. "])}
    },
    "twoFactorManual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select how you want to receive the 2FA-code. You can choose between SMS or mail."])},
    "sms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make sure to you a valid phone number that can receive text messages (SMS). A landline will not work!"])}
  },
  "users": {
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add user"])},
    "addAccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add access"])},
    "customer": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All companies"])},
      "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a customer"])}
    },
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit permissions"])},
    "group": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All groups"])},
      "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a group"])},
      "noGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no groups to choose from"])}
    },
    "location": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All locations"])},
      "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a location"])}
    },
    "steps": {
      "access": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose which groups, companies, or locations you want the user to have access to."])},
        "step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow access"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow user access"])}
      },
      "button": {
        "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next step"])},
        "prev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous Step"])}
      },
      "email": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add one or more email addresses of people you want to add."])},
        "step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Email"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add email addresses"])}
      },
      "invite": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check the details, if everything is correct you can send the invitations by clicking the button."])},
        "step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send invite"])}
      },
      "permissions": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose what permissions you want to give the user."])},
        "step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Give rights"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Give rights to"])}
      }
    },
    "table": {
      "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
      "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy link"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail address"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite link"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])}
    },
    "title": {
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Companies"])},
      "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groups"])},
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locations"])}
    },
    "type": {
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
      "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group"])},
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])}
    },
    "notification": {
      "lastPermission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Removing the last permission in this table will result in a dissabled account without any access. Please be carefull when removing this last permission!"])},
      "noAccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No access is defined for this user, please add access or delete the user account."])}
    }
  },
  "validations": {
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is not a valid email"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password should consist of at least 8 characters. It should also contain at least one uppercase letter, one lowercase letter, one special character."])},
    "phoneValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is not a valid phone number"])},
    "postalCodeValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is not a valid postal code"])},
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is required"])},
    "sameAsPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The passwords don't match"])},
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is not a valid URL."])},
    "validVat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is not a valid VAT number"])},
    "between": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The number should be between ", _interpolate(_named("min")), " and ", _interpolate(_named("max")), "."])},
    "ipExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This IP address is already taken. Please choose another address."])},
    "decimalDot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is not a valid format. Use a dot (.)."])}
  },
  "webhook": {
    "log": {
      "selectCall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a call on the left to see the details"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhook log"])}
    }
  },
  "user": {
    "invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite user"])}
  },
  "tenants": {
    "table": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
      "deactivateOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deactivate on"])},
      "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
      "space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Space(s)"])}
    },
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
    "addTenants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add tenants"])},
    "creating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hold on! We are creating the tenants..."])},
    "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["here."])},
    "uploadCsv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import users from file"])},
    "addManual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add tenants manualy"])},
    "importComplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We successfully completed the import."])},
    "amountImported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New tenants created"])},
    "errorsOccured": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errors while creating"])},
    "deactivated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deactivated"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "editTenant": {
      "chooseDeactivationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a date for future deactivation"])},
      "offboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offboard tenants by selecting a date for deactivation or hit the deactive now button to immediately remove access for a tenant."])},
      "deactivateNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deactivate now?"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit tenant"])},
      "scheduleNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You already scheduled the deactivation of this tenant. Did you did this by accident or want to remove it?"])},
      "undo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove scheduled date"])},
      "saveAndSend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save and send mail"])}
    },
    "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The tenants are created"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "selectSpaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer ruimte"])},
    "edit": {
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changes saved successfully."])}
    }
  },
  "tenant": {
    "csvInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import a CSV file with a header row containing two columns: name, email. On your second row, you add the name in the first column and the email in the second column. Not sure if you are doing this right? Download your example CSV "])},
    "scan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan the QR-code or use the following details to connect"])},
    "wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wifi"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passphrase"])},
    "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hi ", _interpolate(_named("name")), ", here you can find your login information for the wifi at"])},
    "delete": {
      "confirmDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this tenant? Deleting a tenant will remove all settings regarding the tenant."])}
    }
  },
  "locationTypes": {
    "house": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["House"])},
    "horeca": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horeca"])},
    "retail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retail"])},
    "cohousing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cohousing"])},
    "coworking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coworking"])},
    "hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel"])},
    "office": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Office"])},
    "sports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sportfacility"])}
  },
  "financials": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2023 financial goal"])}
  },
  "passwordConfirmModal": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm your password"])},
    "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You need to confirm your password before you can continue."])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm password"])}
  },
  "ppskNotConfigured": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenant menagement is not enabled. Please contact amplr support to help you setting up this feature."])},
  "global": {
    "fiber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiber"])},
    "vdsl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VDSL"])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None"])}
  },
  "payments": {
    "methods": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Methods"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a link to set up a SEPA/credit card method, so that we can charge customers recurrently."])}
    },
    "caasPrice": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create CaaS price"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a new priceId in stripe for a location with connectivity-as-a-service."])}
    },
    "stepList": {
      "openStripe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open this Stripe link to create a subscription"])},
      "pasteProductId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paste this priceId in the product search field"])},
      "metaData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add metadata, use 'siteId' as key and paste the following locationId in value"])},
      "invoiceMemo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paste the following value in the invoice memo field (under advanced)"])},
      "createSubscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Press the 'Create subscription' button to finish."])}
    },
    "createInvoice": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a new invoice"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start invoice creation from here, so the invoice can be linked to the location."])},
      "notification": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A draft invoice is generated and can be accessed here: ", _interpolate(_named("url"))])}
    }
  },
  "modal": {
    "confirm": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure?"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm the deletion by entering:"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You didn't enter the correct value"])},
      "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please confirm your action."])}
    },
    "save": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
      "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Under what name do you want to save this?"])}
    }
  },
  "partner": {
    "profile": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info about the partner"])}
    },
    "address": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The address of the partner"])}
    },
    "access": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add access"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose one or multiple groups, customers or locations which the partner will have access to."])}
    }
  },
  "partnerProfile": {
    "profile": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An overview of your details"])}
    },
    "address": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your address details"])}
    }
  },
  "tag": {
    "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid"])},
    "unpaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unpaid"])},
    "overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overdue"])}
  },
  "referral": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help other entrepreneurs discover Amplr and earn a discount of 200 euros if they become a customer!"])},
    "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the email address, name and company of the person you want to invite. We do the rest!"])},
    "sector": {
      "horeca": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catering industry"])},
      "companies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Companies"])},
      "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Events"])},
      "retail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retail"])}
    },
    "introduced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I already introduced amplr to this person"])}
  },
  "table": {
    "head": {
      "productName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product name"])}
    }
  },
  "legelEntities": {
    "belgium": {
      "nv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limited company (NV)"])},
      "bv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Private company (BV)"])},
      "cv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cooperative Company (CV)"])},
      "vzw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non-profit association (VZW)"])},
      "vof": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partnership under firm (VOF)"])},
      "commv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limited Partnership (Comm V)"])},
      "m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partnership"])},
      "ivzw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["International non-profit association (IVZW)"])}
    },
    "netherland": {
      "e": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proprietorship"])},
      "m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partnership"])},
      "v": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Association"])},
      "s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Founding"])}
    },
    "france": {
      "sarl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société à responsabilité limitée (SARL)"])},
      "sas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société par Actions Simplifiée (SAS)"])},
      "sci": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société Civile Immobilière"])},
      "sa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société Anonyme (SA)"])},
      "scop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société Coopérative et Participative"])}
    }
  },
  "topology": {
    "deviceDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device details"])},
    "device": {
      "ports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview switch ports"])},
      "portName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portname"])},
      "selectPortProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose portprofile"])},
      "connectedDevice": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The client <strong>(", _interpolate(_named("client")), ")</strong> is connected on <strong>port ", _interpolate(_named("portNumber")), "</strong> of this switch <strong>(", _interpolate(_named("switchName")), ")</strong> and has following IP: <strong>", _interpolate(_named("clientIp")), "</strong>."])}
    },
    "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editing a device can have serious impact of the operation of your network. Please be carefull when changing any parameter."])}
  },
  "device": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device name"])},
    "uptime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device uptime"])},
    "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device is offline"])},
    "cellular": {
      "plmnError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provider loading"])}
    }
  },
  "title": {
    "extraHardware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional products"])}
  },
  "text": {
    "installationLog": {
      "extraHardware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Have additional products been placed that are not included in the agreement? Find the products and then add them."])}
    }
  },
  "details": {
    "247": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24/7"])},
    "mailSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email support"])},
    "phoneSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone support"])},
    "businessHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support hours: Mo to Fr from 9am to 5pm"])},
    "businessHoursPlus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support hours: Mo to Fr from 8am to 8pm"])},
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard access"])},
    "dashboardBasic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic dashboard access"])},
    "supportQR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support QR"])},
    "maxDevices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum devices: "])},
    "brandedGuestWifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branded guest wifi"])},
    "deviceUpdates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatic device updates"])}
  },
  "successpage": {
    "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you can see this page, your internet connection is working."])},
    "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success!"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you an AMPLR customer?"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not a customer yet and you want more info?"])}
  },
  "toast": {
    "restored": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restored"])},
    "exportStarted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export started. You will recieve an email when the export is ready to download."])}
  },
  "select": {
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "supplier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suppliers"])}
  },
  "public": {
    "noDeviceGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No systemgroups defined yet."])}
  },
  "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
  "past_due": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Past due"])},
  "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelled"])},
  "connectionType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internet connection"])},
  "failover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failover"])},
  "plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service plan"])},
  "peplink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peplink licence"])},
  "serviceError": {
    "verifyProductBilling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product differs from billing. Verify location and or billing!"])},
    "installedButNotBilled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service is installed but not billed! Please adjust billing."])},
    "billedButNotActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product billed but not active on location. Please check location settings!"])}
  },
  "message": {
    "noSubscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location doesn't have an active subscription. Please check with accounting."])},
    "sales": {
      "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales visit added!"])}
    }
  },
  "sales": {
    "new": {
      "venueDetails": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venue details"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Give the venue details."])}
      },
      "contactDetails": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact details"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in the contact details"])}
      },
      "details": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venue information"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More info on the venue"])}
      },
      "extra": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra information"])}
      },
      "generalInfo": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General information"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in the request general information"])}
      }
    }
  },
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "filter": {
    "sales": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
      "wantsOffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wants quotation"])},
      "followUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Needs follow up"])}
    }
  },
  "search": {
    "noResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No results! Please use another search term."])}
  },
  "filters": {
    "hasNoPaymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Without payment method"])},
    "hasNoLocations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Without locations"])}
  },
  "customers": {
    "noAccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No access rights for this customer"])},
    "invoices": {
      "forceReload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Force reload invoices"])}
    }
  },
  "main": {
    "referral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonus"])}
  },
  "bonus": {
    "hero": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["&#127873; Refer and save money on your next invoice!"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p class=\"mb-3\">At Amplr, we believe that every business has the right to a reliable internet connection. That's why we are constantly dedicated to improving our services and expanding our customer base. </p><p class=\"mb-3\">As a valued Amplr customer, we understand that your unique network and connections are of invaluable importance. </p><p>Therefore, we invite you to explore new locations with us that, like yours, can benefit from our solutions through the Amplr Referral Program.</p>"])},
      "howDoesItWork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How does it work?"])},
      "howDoesItWorkBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you know any companies or individuals within your network that might be interested in Amplr's services? If so, you can now easily refer them to us. All you have to do is get the customer excited about our solutions. We will take care of the rest!"])},
      "whatsInItForYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To thank you for your efforts, we have set up a reward program for successful referrals. If your lead enters into an agreement with Amplr, you will receive a €200 discount on your next invoice. In addition, your referred lead will receive an attractive discount on the one-time setup fee."])},
      "whatsInIt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What's in it for you?"])}
    },
    "page": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referral details"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill out the form to refer a business that can use Amplr for connectivity."])}
    }
  },
  "signal": {
    "excellent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excellent"])},
    "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good"])},
    "fair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fair"])},
    "poor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poor"])},
    "unstable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unstable"])}
  },
  "clients": {
    "table": {
      "tenant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenant"])}
    },
    "reservedClients": {
      "noClients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No configured clients"])}
    },
    "reservations": {
      "ipExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A client with this IP is already configured"])},
      "macAlreadyExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A client with this MAC-address is already configured. You need to remove the reservation first to create a new one."])}
    }
  },
  "unifios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unifi OS"])},
  "omada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TP-Link Omada"])},
  "unificloud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cloud Controller"])},
  "controller": {
    "loginSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Great! We can reach your controller."])},
    "connectionFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connection with your controller failed. Please make sure your controller is reachable from outside the network."])},
    "wrongCredentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We can't make a login call to the controller. Please make sure username and password are correct."])},
    "createSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controller added with success"])}
  },
  "branding": {
    "settings": {
      "form": {
        "headerImage": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Header image"])}
        },
        "logo": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo"])}
        }
      }
    },
    "mail": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalized settings for mails"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalise your mails by using custom colors, header image and logo."])}
    }
  },
  "image": {
    "processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are processing your image"])}
  },
  "deviceGroups": {
    "loadingDevices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading all connected devices..."])}
  },
  "connectionLoss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internet drops out regulary"])},
  "slowWifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slow wifi/guest wifi"])},
  "posIssues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["POS issues (tickets/connection...)"])},
  "supportIssues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provider support is crap"])},
  "soundIssues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sound stuttering"])},
  "followUp": {
    "quote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make quote"])},
    "call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make call"])},
    "notNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not for now"])}
  },
  "copy": {
    "clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy to clipboard"])}
  },
  "invalid": {
    "apiKey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The API key seems to be invalid. Please check the key and try again."])}
  },
  "app": {
    "dashboardTab": {
      "noDeviceGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No device groups configured."])}
    }
  },
  "product": {
    "singular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product"])},
    "notfound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product doesn't exist"])},
    "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product doesn't exist"])},
    "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product updated"])}
  },
  "productModal": {
    "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product model not found"])}
  },
  "productModel": {
    "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product model not found"])}
  },
  "fluvius": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fluvius"])},
  "errorCreatingOmadaLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error creating the Omada location. The file is not compatible. Please make sure you use the correct file (in the config parameters) matching the controller version."])},
  "loadingFull": {
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Putting the dashboard together..."])}
  },
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading..."])},
  "page": {
    "title": {
      "integrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrations"])}
    }
  },
  "site": {
    "devices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device(s)"])}
  },
  "profile": {
    "twoFa": {
      "selectMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a delivery method"])},
      "smsMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text or mail"])},
      "authenticator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TOTP Authenticator"])}
    }
  }
}