import { createWebHistory, createRouter } from 'vue-router';
import store from '@/store';
import UserService from '../services/user';
import routeHistory from './routeHistory';

const routes = [
  {
    path: '/',
    redirect: '/dashboard',
  },

  {
    path: '/auth',
    name: 'auth',
    meta: {
      authModule: true,
      hideForAuth: true,
    },
    component: () => import('@/layouts/AuthLayout.vue'),
    children: [
      {
        path: '',
        name: 'login',
        component: () => import('@/pages/auth/LoginPage.vue'),
      },
      {
        path: 'microsoft/callback',
        name: 'azure',
        component: () => import('@/pages/auth/MicrosoftPage.vue'),
      },
      {
        path: 'register',
        component: () => import('@/pages/auth/RegisterPage.vue'),
      },
      {
        path: 'passwordreset',
        component: () => import('@/pages/auth/ResetPage.vue'),
      },
      {
        path: 'recover',
        component: () => import('@/pages/auth/RecoverPage.vue'),
      },
    ],
  },

  {
    path: '/dashboard',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/layouts/DashboardLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('@/pages/app/DashboardPage.vue'),
      },
    ],
  },
  {
    path: '/peplinkportaltest',
    meta: {
      requiresAuth: false,
    },
    component: () => import('@/test/PortalPeplink.vue'),
  },
  {
    path: '/portal',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/layouts/DashboardLayout.vue'),
    children: [
      {
        path: '',
        name: 'portal',
        components: {
          default: () => import('@/pages/app/portal/SettingsPage.vue'),
          sidebar: () => import('@/components/LocationsList.vue'),
        },
      },
    ],
  },

  {
    path: '/portal/design',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/layouts/DesignerToolLayout.vue'),
    children: [
      {
        path: '',
        components: {
          default: () => import('@/pages/app/portal/DesignPage.vue'),
          preview: () => import('@/pages/app/portal/PreviewTool.vue'),
        },
      },
    ],
  },

  {
    path: '/account',
    name: 'account',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/layouts/DashboardLayout.vue'),
    children: [
      {
        path: '',
        name: 'profile',
        component: () => import('@/pages/app/ProfilePage.vue'),
      },
      {
        path: 'profile',
        component: () => import('@/pages/app/ProfilePage.vue'),
      },
      {
        path: 'profile/microsoft/callback',
        component: () => import('@/pages/app/profile/MicrosoftCallback.vue'),
      },
    ],
  },

  {
    path: '/group',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/layouts/DashboardLayout.vue'),
    children: [
      {
        path: '',
        components: {
          default: () => import('@/pages/app/GroupInfo.vue'),
          sidebar: () => import('@/components/GroupsList.vue'),
        },
      },
    ],
  },

  {
    path: '/group/:id/status',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/layouts/DesignerToolLayout.vue'),
    children: [
      {
        path: '',
        components: {
          default: () => import('@/pages/app/status/DesignPage.vue'),
          preview: () => import('@/pages/app/status/PreviewTool.vue'),
        },
      },
    ],
  },

  {
    path: '/customer/:id/status',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/layouts/DesignerToolLayout.vue'),
    children: [
      {
        path: '',
        components: {
          default: () => import('@/pages/app/status/DesignPage.vue'),
          preview: () => import('@/pages/app/status/PreviewTool.vue'),
        },
      },
    ],
  },

  {
    path: '/locations/:id/status',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/layouts/DesignerToolLayout.vue'),
    children: [
      {
        path: '',
        components: {
          default: () => import('@/pages/app/status/DesignPage.vue'),
          preview: () => import('@/pages/app/status/PreviewTool.vue'),
        },
      },
    ],
  },

  {
    path: '/customer',
    name: 'customer',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/layouts/DashboardLayout.vue'),
    children: [
      {
        path: '',
        name: 'customerLocations',
        meta: {
          requiresAuth: true,
        },
        components: {
          default: () => import('@/pages/app/CustomerPage.vue'),
          sidebar: () => import('@/components/CustomersList.vue'),
        },
        children: [
          {
            path: '',
            name: 'customerLocations',
            components: {
              tab: () => import('@/pages/app/customer/LocationsTab.vue'),
            },
          },
        ],
      },
      {
        path: 'info',
        meta: {
          isPaymentPage: true,
        },
        name: 'customerInfo',
        components: {
          default: () => import('@/pages/app/CustomerPage.vue'),
          sidebar: () => import('@/components/CustomersList.vue'),
        },
        children: [
          {
            path: '',
            name: 'customerInfo',
            components: {
              tab: () => import('@/pages/app/customer/InfoTab.vue'),
            },
          },
        ],
      },
      {
        path: 'invoices',
        meta: {
          requiresAuth: true,
        },
        components: {
          default: () => import('@/pages/app/CustomerPage.vue'),
          sidebar: () => import('@/components/CustomersList.vue'),
        },
        children: [
          {
            path: '',
            components: {
              tab: () => import('@/pages/app/customer/InvoicesTab.vue'),
            },
          },
        ],
      },
      {
        path: 'branding',
        meta: {
          requiresAuth: true,
        },
        components: {
          default: () => import('@/pages/app/CustomerPage.vue'),
          sidebar: () => import('@/components/CustomersList.vue'),
        },
        children: [
          {
            path: '',
            components: {
              tab: () => import('@/pages/app/customer/BrandingTab.vue'),
            },
          },
        ],
      },
    ],
  },
  {
    path: '/integrations',
    name: 'integrations',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/layouts/DashboardLayout.vue'),
    children: [
      {
        path: '',
        name: 'integrationsPage',
        meta: {
          requiresAuth: true,
          requiresPartner: true,
        },
        components: {
          default: () => import('@/pages/app/IntegrationPage.vue'),
        },
        children: [
          {
            path: '',
            name: 'integrationsOverview',
            components: {
              tab: () => import('@/pages/app/integration/OverviewTab.vue'),
            },
          },
        ],
      },
    ],
  },

  {
    path: '/locations',
    name: 'locations',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/layouts/DashboardLayout.vue'),
    children: [
      {
        path: 'dashboard',
        components: {
          default: () => import('@/pages/app/LocationsPage.vue'),
          sidebar: () => import('@/components/LocationsList.vue'),
        },
        children: [
          {
            path: '',
            components: {
              tab: () => import('@/pages/app/locations/DashboardTab.vue'),
            },
          },
        ],
      },
      {
        path: 'topology',
        name: 'locationsTopology',
        components: {
          default: () => import('@/pages/app/LocationsPage.vue'),
          sidebar: () => import('@/components/LocationsList.vue'),
        },
        children: [
          {
            path: '',
            name: 'locationsTopology',
            components: {
              tab: () => import('@/pages/app/locations/TopologyTab.vue'),
            },
          },
        ],
      },
      {
        path: 'tenants',
        name: 'tenantsManagement',
        components: {
          default: () => import('@/pages/app/LocationsPage.vue'),
          sidebar: () => import('@/components/LocationsList.vue'),
        },
        children: [
          {
            path: '',
            name: 'tenantsManagement',
            components: {
              tab: () => import('@/pages/app/locations/TenantsTab.vue'),
            },
          },
        ],
      },
      {
        path: 'systems',
        name: 'locationsSystems',
        components: {
          default: () => import('@/pages/app/LocationsPage.vue'),
          sidebar: () => import('@/components/LocationsList.vue'),
        },
        children: [
          {
            path: '',
            name: 'locationsSystems',
            components: {
              tab: () => import('@/pages/app/locations/DeviceGroupsTab.vue'),
            },
          },
        ],
      },
      {
        path: 'clients',
        components: {
          default: () => import('@/pages/app/LocationsPage.vue'),
          sidebar: () => import('@/components/LocationsList.vue'),
        },
        children: [
          {
            path: '',
            components: {
              tab: () => import('@/pages/app/locations/ClientsTab.vue'),
            },
          },
        ],
      },
      {
        path: 'portal',
        name: 'locationsPortal',
        components: {
          default: () => import('@/pages/app/LocationsPage.vue'),
          sidebar: () => import('@/components/LocationsList.vue'),
        },
        children: [
          {
            path: '',
            name: 'defaultLocationPortal',
            components: {
              tab: () => import('@/pages/app/locations/PortalTab.vue'),
            },
          },
        ],
      },
      {
        path: 'history',
        components: {
          default: () => import('@/pages/app/LocationsPage.vue'),
          sidebar: () => import('@/components/LocationsList.vue'),
        },
        children: [
          {
            path: '',
            components: {
              tab: () => import('@/pages/app/locations/HistoryTab.vue'),
            },
          },
        ],
      },
      {
        path: 'info',
        components: {
          default: () => import('@/pages/app/LocationsPage.vue'),
          sidebar: () => import('@/components/LocationsList.vue'),
        },
        children: [
          {
            path: '',
            components: {
              tab: () => import('@/pages/app/locations/InfoTab.vue'),
            },
          },
        ],
      },
    ],
  },

  {
    path: '/users',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/layouts/DashboardLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('@/pages/app/UsersPage.vue'),
        children: [
          {
            path: '',
            components: {
              tab: () => import('@/pages/app/users/UsersTab.vue'),
            },
          },
        ],
      },
      {
        path: 'invites',
        component: () => import('@/pages/app/UsersPage.vue'),
        children: [
          {
            path: '',
            components: {
              tab: () => import('@/pages/app/users/UserInvites.vue'),
            },
          },
        ],
      },
    ],
  },

  {
    path: '/financials/hidden',
    name: 'financials',
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
    component: () => import('@/pages/admin/FinancialsPage.vue'),
  },
  {
    path: '/admin',
    name: 'admin',
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
    component: () => import('@/layouts/DashboardLayout.vue'),
    children: [
      { path: '', name: 'dashboard', redirect: '/admin/dashboard' },
      {
        path: 'dashboard',
        component: () => import('@/pages/admin/DashboardPage.vue'),
      },

      {
        path: 'customers',
        component: () => import('@/pages/admin/CustomersPage.vue'),
      },
      {
        path: 'locations',
        component: () => import('@/pages/admin/LocationsPage.vue'),
      },
      {
        path: 'users',
        component: () => import('@/pages/app/UsersPage.vue'),
        children: [
          {
            path: '',
            name: 'adminUsers',
            components: {
              tab: () => import('@/pages/app/users/UsersTab.vue'),
            },
          },
        ],
      },
      {
        path: 'users/invites',
        component: () => import('@/pages/app/UsersPage.vue'),
        children: [
          {
            path: '',
            name: 'adminUsersInvites',
            components: {
              tab: () => import('@/pages/app/users/UserInvites.vue'),
            },
          },
        ],
      },
      {
        path: 'config',
        component: () => import('@/pages/admin/ConfigPage.vue'),
      },

      {
        path: 'controllers',
        component: () => import('@/pages/admin/ControllersPage.vue'),
      },
      {
        path: 'zerotier',
        component: () => import('@/pages/admin/ZerotierPage.vue'),
      },
      {
        path: 'payments',
        component: () => import('@/pages/admin/PaymentsPage.vue'),
      },
      {
        path: 'partners',
        component: () => import('@/pages/admin/PartnersPage.vue'),
      },
      {
        path: 'subscriptions',
        component: () => import('@/pages/admin/SubscriptionPage.vue'),
      },
    ],
  },
  {
    path: '/admin/inventory',
    component: () => import('@/layouts/DashboardLayout.vue'),
    children: [
      {
        path: '',
        name: 'inventorypage',
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
        },
        components: {
          default: () => import('@/pages/admin/InventoryPage.vue'),
        },
        children: [
          {
            path: '',
            name: 'inventorytab',
            components: {
              tab: () => import('@/pages/admin/inventory/InventoryTab.vue'),
            },
          },
          {
            path: 'caas',
            name: 'allocatedTab',
            components: {
              tab: () => import('@/pages/admin/inventory/AllocatedTab.vue'),
            },
          },
          {
            path: 'products',
            name: 'productstab',
            components: {
              tab: () => import('@/pages/admin/inventory/ProductsTab.vue'),
            },
          },
          {
            path: 'logs',
            name: 'logsTab',
            components: {
              tab: () => import('@/pages/admin/inventory/InventoryLogsTab.vue'),
            },
          },
        ],
      },
    ],
  },
  {
    path: '/admin/sales',
    component: () => import('@/layouts/DashboardLayout.vue'),
    children: [
      {
        path: '',
        name: 'salespageMain',
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
        },
        components: {
          default: () => import('@/pages/admin/SalesPage.vue'),
        },
        children: [
          {
            path: '',
            name: 'salespage',
            components: {
              tab: () => import('@/pages/admin/sales/OverviewTab.vue'),
            },
          },
          {
            path: 'create',
            name: 'create',
            components: {
              tab: () => import('@/pages/admin/sales/CreateVisitTab.vue'),
            },
          },
          {
            path: 'calculate',
            name: 'calculator',
            components: {
              tab: () => import('@/pages/admin/sales/ConfigCalculatorTab.vue'),
            },
          },
        ],
      },
    ],
  },
  {
    path: '/peplink',
    meta: {
      requiresAuth: false,
      isPublic: true,
    },
    component: () => import('@/layouts/PublicLayout.vue'),
    children: [
      {
        path: '',
        name: 'peplink',
        component: () => import('@/pages/portal/PortalContainer.vue'),
      },
    ],
  },
  {
    path: '/guest',
    meta: {
      requiresAuth: false,
      isPublic: true,
    },
    component: () => import('@/layouts/PublicLayout.vue'),
    children: [
      {
        path: '',
        name: 'omada',
        component: () => import('@/pages/portal/PortalContainer.vue'),
      },
      {
        path: 's/:site',
        name: 'unifi',
        component: () => import('@/pages/portal/PortalContainer.vue'),
      },
      {
        path: 'bloovi',
        name: 'bloovi',
        component: () => import('@/pages/portal/BlooviPage.vue'),
      },
    ],
  },

  {
    path: '/tenants',
    name: 'tenants-public',
    meta: {
      requiresAuth: false,
      isPublic: true,
    },
    component: () => import('@/layouts/PublicLayout.vue'),
    children: [
      {
        path: ':id',
        component: () => import('@/pages/public/TenantPage.vue'),
        name: 'tenant-page',
      },
    ],
  },
  {
    path: '/status',
    name: 'status',
    meta: {
      requiresAuth: false,
      isPublic: true,
    },
    component: () => import('@/layouts/PublicLayout.vue'),
    children: [
      {
        path: '',
        name: 'status',
        component: () => import('@/pages/status/StatusPage.vue'),
      },
      { path: ':id', component: () => import('@/pages/status/StatusPage.vue') },
    ],
  },

  {
    path: '/terms-and-conditions',
    name: 'terms',
    meta: {
      requiresAuth: false,
      isPublic: true,
    },
    component: () => import('@/layouts/PublicLayout.vue'),
    children: [
      {
        path: '',
        name: 'terms',
        component: () => import('@/pages/public/TermsPage.vue'),
      },
    ],
  },

  {
    path: '/mydata',
    name: 'mydata',
    meta: {
      requiresAuth: false,
      isPublic: true,
    },
    component: () => import('@/layouts/PublicLayout.vue'),
    children: [
      {
        path: '',
        name: 'mydata',
        component: () => import('@/pages/public/DataPage.vue'),
      },
      { path: ':type', component: () => import('@/pages/public/DataPage.vue') },
    ],
  },

  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: () => import('@/pages/404Page.vue'),
  },

  {
    path: '/no-access',
    meta: {
      requiresAuth: false,
      isPublic: true,
    },
    component: () => import('@/layouts/PublicLayout.vue'),
    children: [
      {
        path: '',
        name: 'no-access',
        component: () => import('@/pages/public/NoAccess.vue'),
      },
    ],
  },

  {
    path: '/not-payed',
    component: () => import('@/layouts/DashboardLayout.vue'),
    children: [
      {
        path: '',
        name: 'not-payed',
        component: () => import('@/pages/public/NotPayed.vue'),
      },
    ],
  },

  {
    path: '/maintenance',
    name: 'maintenance',
    component: () => import('@/layouts/PublicLayout.vue'),
    children: [
      {
        path: '',
        name: 'maintenance',
        component: () => import('@/pages/MaintenancePage.vue'),
      },
    ],
  },
  {
    path: '/bonus',
    name: 'bonus',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/layouts/DashboardLayout.vue'),
    children: [
      {
        path: '',
        name: 'bonuspage',
        component: () => import('@/pages/app/BonusPage.vue'),
      },
    ],
  },
  {
    path: '/partner',
    name: 'partner',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/layouts/DashboardLayout.vue'),
    children: [
      {
        path: 'getstarted',
        name: 'getstarted',
        component: () => import('@/pages/app/GetStartedPage.vue'),
      },
      {
        path: 'users',
        name: 'users',
        component: () => import('@/pages/app/PartnerUsers.vue'),
        children: [
          {
            path: '',
            name: 'users',
            components: {
              tab: () => import('@/pages/app/partners/UsersTab.vue'),
            },
          },
        ],
      },
      {
        path: 'invites',
        component: () => import('@/pages/app/PartnerUsers.vue'),
        children: [
          {
            path: '',
            components: {
              tab: () => import('@/pages/app/partners/UserInvites.vue'),
            },
          },
        ],
      },
      {
        path: '',
        name: 'info',
        component: () => import('@/pages/app/PartnerInfo.vue'),
      },
    ],
  },

  {
    path: '/success',
    meta: {
      requiresAuth: false,
      isPublic: true,
    },
    component: () => import('@/layouts/PublicLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('@/pages/public/SuccessPage.vue'),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: 'is-active',
  linkExactActiveClass: 'is-active',
  scrollBehavior(to) {
    if (to.query.scrollTo) {
      return { el: `#${to.query.scrollTo}`, behavior: 'smooth' };
    }
    return { top: 0 }; // Default scroll to top
  },
});

let rendered = false;

router.beforeEach((to, from, next) => {
  routeHistory.previousPath = from.path;
  next();
});

router.beforeEach((to, from, next) => {
  if (rendered) {
    next();
  } else if (to.meta.requiresAuth && store.state.user.isLoggedIn) {
    UserService.getUser().then((response) => {
      rendered = true;

      const data = response.data.data;

      store.commit('SET_USER', {
        id: data.id,
        name: data.name,
        firstname: data.firstname,
        lang: data.lang,
        isAdmin: data.is_admin,
        isPartner: data.is_partner,
        hasGroups: data.has_groups,
        hasCustomers: data.has_customers,
        hasLocations: data.has_locations,
        hasUserPermissions: data.has_user_permissions,
        hasPortalPermissions: data.has_portal_permissions,
        partnerLogo: data.partner_logo ? data.partner_logo : null,
        imposterMode: data.imposter_mode ? data.imposter_mode : null,
        defaultPartnerId: data.default_partner ? data.default_partner.id : null,
      });

      store.commit('SET_GROUP', {
        id: data.default_group?.id,
        permissions: data.default_group?.permissions,
      });

      store.commit('SET_CUSTOMER', {
        id: data.default_customer?.id,
        paymentMethod: data.default_customer?.payment_method,
        type: data.default_customer?.demo_mode,
        b2b: data.default_customer?.type === 1,
        freeTier: data.default_customer?.free_tier || false,
        permissions: data.default_customer?.permissions,
      });

      store.commit('SET_LOCATION', {
        id: data.default_location?.id,
        name: data.default_location?.name,
        plan: data.default_location?.plan,
        paymentMethod: data.default_location?.payment_method,
        permissions: data.default_location?.permissions,
      });

      next();
    });
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  let isMaintenance = process.env.VUE_APP_MAINTENANCE;
  if (isMaintenance && !to.path.includes('maintenance')) {
    next('/maintenance');
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  const isLoggedIn = store.state.user.isLoggedIn;
  const paymentMethodCustomer = store.state.customer.paymentMethod;
  const paymentMethodLocation = store.state.location.paymentMethod;
  const demoCustomer = store.state.customer.type;
  const isAdmin = store.state.user.isAdmin;
  const isPartner = store.state.user.isPartner;

  if (to.meta.requiresAuth) {
    if (!isLoggedIn) {
      next({
        path: '/auth',
        query: to ? { path: to.path } : null,
      });
    } else {
      if (to.matched.some((record) => record.meta.requiresAdmin)) {
        if (!isAdmin) {
          next('/');
        } else {
          next();
        }
      } else if (to.matched.some((record) => record.meta.requiresPartner)) {
        if (!isPartner) {
          next('/');
        } else {
          next();
        }
      } else if (
        !store.state.customer.id &&
        !store.state.location.id &&
        store.state.group.id
      ) {
        next();
      } else if (
        !isAdmin &&
        !isPartner &&
        !demoCustomer &&
        !store.state.customer.id &&
        !paymentMethodLocation &&
        !to.matched.some((record) => record.meta.isPaymentPage)
      ) {
        next({
          path: '/not-payed',
        });
      } else if (
        !isAdmin &&
        !isPartner &&
        !demoCustomer &&
        store.state.customer.id &&
        !paymentMethodCustomer &&
        !to.matched.some((record) => record.meta.isPaymentPage)
      ) {
        next({
          path: '/customer/info',
        });
      } else {
        next();
      }
    }
  } else {
    next();
  }
});

// If we are on a certain subdomain, only specific pages can be visited.
router.beforeEach((to, from, next) => {
  const subdomains = ['portal', 'status'];
  const host = window.location.hostname;
  const subdomain = host.split('.')[0];

  if (
    subdomains.includes(subdomain) &&
    !to.matched.some((record) => record.meta.isPublic)
  ) {
    next('/no-access');
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  const isPartner = store.state.user.isPartner;
  if (
    to.matched.some((record) => record.name === 'locationsPortal') &&
    isPartner
  ) {
    router.go(-1);
  } else {
    next();
  }
});
router.beforeEach((to, from, next) => {
  if (
    to.matched.some((record) => record.meta.hideForAuth) &&
    typeof store.state.user !== 'undefined' &&
    store.state.user.isLoggedIn
  ) {
    next('/');
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  const isLocation =
    !store.state.user.hasGroups && !store.state.user.hasCustomers;
  const isAdmin = store.state.user.isAdmin;
  const isPartner = store.state.user.isPartner;

  // New condition for when isLocation and isPartner are both true
  if (to.path === '/dashboard' && isLocation && isPartner) {
    next('/partner/getstarted');
  }
  // Existing condition for isLocation but not admin or partner
  else if (to.path === '/dashboard' && isLocation && !isAdmin && !isPartner) {
    next('/locations/dashboard');
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  const currentUserEmail = store.state.user.id;
  const isAccessingSalesRoutes = to.path.startsWith('/admin/sales');
  // Check if the route is within /admin/sales/*
  if (currentUserEmail === '7f4b4e31-1f63-40e3-9fb3-ba09aa192f7a') {
    if (
      isAccessingSalesRoutes ||
      !to.matched.some((record) => record.meta.requiresAuth)
    ) {
      next(); // Allow access to /admin/sales/* for sales@amplr.be
    } else {
      next('/admin/sales'); // Restrict access to other routes for sales@amplr.be
    }
  } else {
    next(); // Allow access to all routes for all other users
  }
});

export default router;
